
import { defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Field, Form } from "vee-validate";
import { object, string, array } from "yup";
import membersApi from "@/core/services/MembersApi";
import Places from "./crafted/widgets/Places.vue";
import { ElMessage } from "element-plus";
import institutionApi from "@/core/services/InstitutionApi";
import axios from "axios";

export default defineComponent({
    name: "member-update",
    components: { Form, Field, Places },
    data() {
        return {
            placesKey: 0,
            spouseKey: 0,
            loading: true,
            member: {} as any,
            hasChildren: "",
            childrenMemberChaine: "",
            remoteChild: "",
            memberChildren: [] as any,
            institutionQuery: "",
            types: [],
            stars: [
                {
                    label: "1",
                    value: "1",
                },
                {
                    label: "2",
                    value: "2",
                },
                {
                    label: "3",
                    value: "3",
                },
                {
                    label: "4",
                    value: "4",
                },
                {
                    label: "5",
                    value: "5",
                },
            ],
            food_styles: [] as any,
            credit_cards: [] as any,
            formValues: {
                general: {
                    title: "",
                    firstname: "",
                    lastname: "",
                    sex: "",
                    birthdate: "",
                    nationality: "",
                    spoken_languages: [],
                    spouse: "",
                    other_associations: "",
                },
                location: {
                    additional_information: "",
                    address: "",
                    country: "",
                    state: "",
                    postal_code: "",
                    city: "",
                },
                contact: {
                    phone: "",
                    mobile: "",
                    email: "",
                },
                infos: {
                    grade: "",
                    conseil: "",
                },
                omgd: {
                    active: false,
                    nomination_date: "",
                    induction_date: "",
                    induction_place: "",
                },
                commanders: [],
                medals: [],
            },
            memberSpouse: {
                id: "",
                label: "",
            },
            isMobile: false,
            nationalities: [] as any,
            statuses: [] as any,
            titles: [] as any,
            remoteMembers: [] as any,
            remoteInstitutions: [] as any,
            states: [] as any,
            cities: [] as any,
            removeInstitImage: false,
            sexes: [
                {
                    label: "Male",
                    value: "M",
                },
                {
                    label: "Female",
                    value: "F",
                },
            ],
            institution: {} as any,
            languages: [],
            remoteInstitutionName: "",
            institutionName: "",
            institutionSelected: false,
            displayInstitutionForm: false,
        };
    },
    setup() {
        const formSchema = object().shape({
            title: string().required().label("Title").nullable(),
            firstname: string()
                .required()
                .required()
                .label("Firstname")
                .nullable(),
            lastname: string()
                .required()
                .required()
                .label("Lastname")
                .nullable(),
            sex: string().required().label("Gender").nullable(),
            birthdate: string().required().label("Birthdate").nullable(),
            nationality: string().required().label("Nationality").nullable(),
            spoken_languages: array()
                .min(1, "Please select at least one language")
                .label("Languages"),
            spouse: string().label("Spouse member").nullable(),
            other_associations: string().label("Other associations").nullable(),
            address: string().nullable().label("Member street").nullable(),
            additional_information: string()
                .nullable()
                .label("Additional information")
                .nullable(),
            country: string().label("Member country").nullable(),
            state: string().label("Member state").nullable(),
            postal_code: string().nullable().label("Member zip").nullable(),
            city: string().label("Member city").nullable(),
            phone: string().required().label("Member phone").nullable(),
            email: string().required().email().label("Member email").nullable(),
        });
        return { route: useRoute(), router: useRouter(), formSchema };
    },
    mounted() {
        window.addEventListener("resize", this.getDimensions);
        this.getDimensions();
        this.getFields();
        this.getMember();
    },
    unmounted() {
        window.removeEventListener("resize", this.getDimensions);
    },
    methods: {
        formatDate(date: string, locale?: string) {
            if (date !== null && date !== undefined) {
                var message: string;

                if (locale)
                    message = new Date(date.split(" ")[0])
                        .toLocaleString(locale)
                        .split(" ")[0];
                else
                    message = new Date(date.split(" ")[0])
                        .toLocaleString()
                        .split(" ")[0];

                return message;
            } else {
                return "";
            }
        },
        initInstitutionForm() {
            this.institutionSelected = false;
            this.displayInstitutionForm = true;
            this.institution = { address: {}, contact: {} };
        },
        fakeSubmit() {
            document.getElementById("submitPage")?.click();
        },
        submitMemberPage() {
            const payload = {
                title: this.formValues.general.title,
                sex: this.formValues.general.sex,
                birthdate: this.formValues.general.birthdate,
                nationality: this.formValues.general.nationality,
                spoken_languages: this.formValues.general.spoken_languages,
                spouse_member: this.memberSpouse.id,
                other_associations: this.formValues.general.other_associations,
                parent1: this.member.parent1 ? this.member.parent1.id : null,
                parent2: this.member.parent2 ? this.member.parent2.id : null,
                children_over_18: this.hasChildren === "Yes" ? true : false,
                children_in_chaine:
                    this.childrenMemberChaine === "Yes" ? true : false,
                childrens: this.memberChildren.map((child: any) => child.id),
                address: {
                    ...this.formValues.location,
                },
                contact: {
                    ...this.formValues.contact,
                },
                userprofile: {
                    firstname: this.formValues.general.firstname,
                    lastname: this.formValues.general.lastname,
                },
            } as any;

            if (this.member.is_pro) {
                payload["institution"] = {
                    ...this.institution,
                };

                if (this.removeInstitImage) {
                    payload.institution.picture = "";
                    payload.institution.pictureName = "";
                } else {
                    delete payload.institution.pictureName;
                    delete payload.institution.picture;
                }
            }

            membersApi
                .updateMember(this.route.params.member_id, payload)
                .then((res: any) => {
                    if (res.success) {
                        const fileInput = document.getElementById(
                            "institPic"
                        ) as any;
                        let file = "" as any;
                        let fileInstance = "" as any;

                        // if (fileInstance)
                        file = fileInput?.files[0];

                        if (file) {
                            fileInstance = new File([file], file.name);

                            const formData = new FormData();
                            formData.append("file", fileInstance);
                            axios
                                .post(
                                    "/api/v1/institution/" +
                                        this.institution.id +
                                        "/upload-image/",
                                    formData,
                                    {
                                        headers: {
                                            "Content-Type":
                                                "multipart/form-data",
                                        },
                                    }
                                )
                                .catch((error: any) => {
                                    console.log(error);
                                });
                        }
                        ElMessage({
                            type: "success",
                            message: "Successfully updated information.",
                        });
                        this.router.push({
                            name: "member-card",
                            params: { member_id: this.route.params.member_id },
                        });
                    }
                });
        },
        getDimensions() {
            const width = document.documentElement.clientWidth;
            if (width < 768) this.isMobile = true;
            else this.isMobile = false;
        },
        getMember() {
            membersApi
                .getSpecificMember(this.route.params.member_id)
                .then((res: any) => {
                    this.member = res.data;
                    this.hasChildren = this.member.children_over_18
                        ? "Yes"
                        : "No";
                    this.childrenMemberChaine = this.member.children_in_chaine
                        ? "Yes"
                        : "No";
                    this.memberChildren = this.member.childrens;
                    this.formValues = {
                        general: {
                            title: this.member.title
                                ? this.member.title.id
                                : "",
                            firstname: this.member.userprofile
                                ? this.member.userprofile.firstname
                                : "",
                            lastname: this.member.userprofile
                                ? this.member.userprofile.lastname
                                : "",
                            sex: this.member.sex ? this.member.sex : "",
                            birthdate: this.member.birthdate
                                ? this.member.birthdate
                                : "",
                            nationality: this.member.nationality
                                ? this.member.nationality.id
                                : "",
                            spoken_languages: this.member.spoken_languages
                                ? this.member.spoken_languages.map(
                                      (lang: any) => lang.id
                                  )
                                : [],
                            spouse: this.member.spouse_member
                                ? this.member.spouse_member
                                : "",
                            other_associations: this.member.other_associations
                                ? this.member.other_associations
                                : "",
                        },
                        location: {
                            additional_information: this.member.address
                                ? this.member.address.additional_information
                                : "",
                            address: this.member.address
                                ? this.member.address.address
                                : "",
                            country: this.member.address
                                ? this.member.address.country
                                : "",
                            state: this.member.address
                                ? this.member.address.state
                                : "",
                            postal_code: this.member.address
                                ? this.member.address.postal_code
                                : "",
                            city: this.member.address
                                ? this.member.address.city
                                : "",
                        },
                        contact: {
                            phone: this.member.contact.phone
                                ? this.member.contact.phone
                                : "",
                            mobile: this.member.contact.mobile
                                ? this.member.contact.mobile
                                : "",
                            email: this.member.contact.email
                                ? this.member.contact.email
                                : "",
                        },
                        infos: {
                            grade: this.member.grade ? this.member.grade : "",
                            conseil: this.member.grade_conseil
                                ? this.member.grade_conseil
                                : "",
                        },
                        omgd: {
                            active: this.member.is_omgd,
                            nomination_date: this.member.omgd_nomination_date
                                ? this.member.omgd_nomination_date
                                : "",
                            induction_date: this.member.omgd_intronisation_date
                                ? this.member.omgd_intronisation_date
                                : "",
                            induction_place: this.member
                                .omgd_intronisation_chapitre_location
                                ? this.member
                                      .omgd_intronisation_chapitre_location
                                : "",
                        },
                        commanders: this.member.commanders,
                        medals: this.member.medals,
                    };
                    if (this.member.spouse_member) {
                        membersApi
                            .getSpecificMember(this.member.spouse_member)
                            .then((spouse: any) => {
                                this.memberSpouse.id = spouse.data.id;
                                this.memberSpouse.label =
                                    spouse.data.userprofile.firstname +
                                    " " +
                                    spouse.data.userprofile.lastname;
                                this.spouseKey++;
                            });
                    }
                    if (this.member.institution) {
                        this.institutionQuery = this.member.institution.name;
                        this.institution = this.member.institution;
                        this.institutionSelected = true;
                        this.institutionName = this.member.institution.name;
                        if (this.institution.address === null)
                            this.institution.address = {};
                        if (this.institution.contact === null)
                            this.institution.contact = {};
                        this.institution.food_styles =
                            this.member.institution.food_styles.map(
                                (item: any) => {
                                    return item.id;
                                }
                            );
                        this.institution.credit_cards =
                            this.member.institution.credit_cards.map(
                                (item: any) => {
                                    return item.id;
                                }
                            );
                        this.institution.institution_type = this.member
                            .institution.institution_type
                            ? this.member.institution.institution_type.id
                            : "";
                    }
                    this.placesKey++;
                    this.loading = false;
                })
                .catch((err: any) => console.log(err));
        },
        getFields() {
            membersApi.getNationalities({ p: 1, presult: 9999 }).then((res) => {
                this.nationalities = res.data.object_list;
            });
            membersApi
                .getMaritalStatuses({ p: 1, presult: 9999 })
                .then((res) => {
                    this.statuses = res.data.object_list;
                });
            membersApi.getTitles({ p: 1, presult: 9999 }).then((res) => {
                this.titles = res.data.object_list;
            });
            membersApi.getSpokenLanguages().then((res: any) => {
                this.languages = res.data;
            });
            membersApi
                .getInstitutionTypes({ p: 1, presult: 9999 })
                .then((res) => {
                    this.types = res.data.object_list;
                });
            institutionApi
                .getFoodStyles({ p: 1, presult: 9999 })
                .then((res) => {
                    this.food_styles = res.data.object_list;
                });
            institutionApi
                .getCreditCards({ p: 1, presult: 9999 })
                .then((res) => {
                    this.credit_cards = res.data.object_list;
                });
        },
        handleImage(e) {
            this.institution.picture = e.target.files[0] as any;
            this.institution.pictureName = e.target.files[0].name;
            const reader = new FileReader();
            reader.onload = (ev) => {
                if (ev.target) {
                    this.institution.picture = ev.target.result as any;
                }
            };
            if (this.institution.picture)
                reader.readAsDataURL(this.institution.picture as any);
        },
        removeImage() {
            const input = document.getElementById("institPic") as any;
            if (input) input.value = "";

            this.institution.picture = "";
            this.institution.pictureName = "";
            this.removeInstitImage = true;
        },
        querySearchAsync(queryString, cb) {
            this.loadRemoteMembers(queryString).then(() => {
                cb(this.remoteMembers);
            });
        },
        loadRemoteMembers(query) {
            return new Promise((resolve, reject) => {
                if (query !== "" && query.length >= 2) {
                    this.remoteMembers = [];
                    membersApi.listRemote({ query: query }).then((response) => {
                        if (response.success) {
                            response.data.map((remoteMember) => {
                                this.remoteMembers.push({
                                    id: remoteMember.id,
                                    firstname:
                                        remoteMember.userprofile.firstname,
                                    lastname: remoteMember.userprofile.lastname,
                                    code: remoteMember.code,
                                });
                            });
                            resolve("");
                        } else {
                            reject("");
                        }
                    });
                } else {
                    this.remoteMembers = [];
                    resolve("");
                }
            });
        },
        loadRemoteInstitutions(query) {
            return new Promise((resolve, reject) => {
                if (query) {
                    if (query !== "" && query.length >= 2) {
                        this.remoteInstitutions = [];
                        institutionApi
                            .listRemote({ query: query })
                            .then((response) => {
                                if (response.success) {
                                    response.data.map((remoteInstitution) => {
                                        this.remoteInstitutions.push({
                                            ...remoteInstitution,
                                            name: remoteInstitution.name,
                                            id: remoteInstitution.id,
                                        });
                                    });
                                    resolve("");
                                } else {
                                    reject("");
                                }
                            });
                    } else {
                        this.remoteMembers = [];
                        resolve("");
                    }
                }
            });
        },
        querySearchAsyncInstitution(queryString, cb) {
            this.loadRemoteInstitutions(queryString).then(() => {
                cb(this.remoteInstitutions);
            });
        },
        handleSelect(item: any, input: any) {
            if (input === "spouse_member") {
                this.memberSpouse.label = item.firstname + " " + item.lastname;
                this.memberSpouse.id = item.id;
                this.remoteMembers = [];
            } else if (input === "child") {
                this.memberChildren.push(item);
            } else if (input === "institution") {
                console.log("item", item);
                this.displayInstitutionForm = false;
                this.institutionName = item.name;
                this.institutionSelected = true;

                this.institution = {
                    ...this.institution,
                    ...item,
                    institution_type: item.institution_type
                        ? item.institution_type.id
                        : null,
                };

                if (this.institution.contact === null)
                    this.institution.contact = {};
                if (this.institution.address === null)
                    this.institution.address = {};
                this.institution.food_styles = item.food_styles.map(
                    (item: any) => {
                        return item.id;
                    }
                );
                this.institution.credit_cards = item.credit_cards.map(
                    (item: any) => {
                        return item.id;
                    }
                );

                this.placesKey++;
            }
        },
        handleClear() {
            console.log("clear");

            this.formValues.location = {
                additional_information: "",
                country: "",
                state: "",
                city: "",
                postal_code: "",
                address: "",
            };
        },
        setPlaceInfo(scope: string, value: any) {
            switch (scope) {
                case "pro":
                    this.institution.address = {
                        country: value.country,
                        country_code: value.iso2,
                        state: value.state,
                        postal_code: value.postalCode,
                        address: value.streetNumber + " " + value.street,
                        city: value.city,
                        additional_information:
                            this.institution.additional_information,
                    };
                    break;
                case "perso":
                    console.log("value", value);

                    this.formValues.location = {
                        additional_information: "",
                        country: value.country,
                        state: value.state,
                        postal_code: value.postalCode,
                        address: value.streetNumber + " " + value.street,
                        city: value.city,
                    };
                    // this.reloadLocations("member", false);
                    break;
                default:
                    break;
            }
        },
    },
});
